import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";
import { ShowToast, Severty } from "../../helper/toast";
import GoogleImg from "../../assets/images/google-plus.png";
import { showError } from "../../helper/functions";
import lang from "../../helper/langHelper";

const GoogleLogin = () => {
  const provider = new GoogleAuthProvider();
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const location = window.location.pathname
  const navigate = useNavigate();
  const auth = getAuth();

  const { request } = useRequest();

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((res) => {
        const { user } = res;

        const payload = {
          name: user.providerData[0].displayName,
          email: user.providerData[0].email,
          social_id: user.providerData[0].uid,
          image: user.providerData[0].photoURL,
        };

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            payload.latitude = position.coords.latitude;
            payload.longitude = position.coords.longitude;
          }, showError);
        } else {
          ShowToast("Geolocation is not supported by this browser.", Severty.ERROR);
        }

        console.log(payload, "\nPayload------------------->");

        request({
          url: "app/auth/social-signup",
          method: "POST",
          data: payload,
          onSuccess: (data) => {
            if (data.status) {
              setIsLoggedIn(true);
              setUserProfile(data.user);
              ShowToast(data.message, Severty.SUCCESS);
              localStorage.setItem("token", data.token);
              localStorage.setItem("userProfile", JSON.stringify(data.user));
             if(location === "/" || location === "/login" || location === "/signup") setTimeout(() => navigate("/home"), 200);
            } else {
              ShowToast(data.message, Severty.ERROR);
            }
          },
          onError: (error) => {
            ShowToast(error?.response?.data?.message, Severty.ERROR);
          },
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(
          `Error signing in with Google: ${errorCode} - ${errorMessage}`
        );
      });
  };

  return (
    <button
      onClick={handleGoogleSignIn}
      disabled={loading}
      className="google-button-img"
    >
      {loading ? (
        <>
          <img width={35} src={GoogleImg} alt="Google" />
          {" Logging in..."}
        </>
      ) : (
        <>
          <img width={35} src={GoogleImg} alt="Google" />
          {lang("Login with Google")}
        </>
      )}
    </button>
  );
};

export default GoogleLogin;
