import { notification } from "antd";

let appMode = process.env.REACT_APP_ENV;
let ASSET_URL =
  "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://backend.planit-co.com/api/";
  // URL = "http://localhost:8900/api/";
} else {
  URL = "https://backend.planit-co.com/api/";
  // URL = "http://localhost:7900/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,

  // Header APIsmain

  globalSearch: "app/global-search",
  notification: "app/notification/",

  // Auth APIs
  support: "app/auth/support",

  profile: "app/auth/profile",
  signUp: "app/auth/sign-up",
  editProfile: "app/auth/profile",
  updateProfileImage: "app/auth/profile/image",
  sendOtp: "app/auth/send-otp",
  login: "app/auth/login",
  forgotPassword: "app/auth/forget-password",
  resetPassword: "app/auth/reset-password",
  verifyOtp: "app/auth/verify-otp",
  emailVerify: "app/auth/verify-email",
  resendOtp: "app/auth/resend-otp",
  contactUs: "app/auth/contact-us",
  deleteProfile: "app/auth/delete-profile",
  editNotificationSetting: "app/auth/edit-notification-settings",

  // Home Page--->
  categories: "app/category",
  homeEvent: "app/event-type",
  about: "app/content",
  bannerClicks: "app/banner/clicks",

  // Service Page--->
  services: "app/service",
  subCategories: "app/sub-category",
  serviceByEventType: "/app/service/services-by-eventype",

  // Provder Page ---->
  getProvider: "/app/provider",
  getCount: "/app/provider/call-count",
  updateViewCount: "/app/provider/view-count",
  reportProvider: "/app/report/provider",
  providerRatingList: "/app/rating/ratings",
  providerRating: "/app/rating/provider",
  addBudget: "/app/user-event/budget-service/add",
  addPkgBudget: "/app/user-event/budget-package/add",

  // Quote Page ---->
  requestQuote: "/app/quote",
  providerServices: "/app/quote/provider-services",
  providerPackages: "/app/quote/provider-packages",

  // Event Page ---->
  eventList: "/app/user-event",
  addEvent: "/app/user-event",
  editEvent: "app/user-event",
  inviteUserGuest: "/app/user-guest/invite-user-guest",
  eventCollab: "app/user-guest/event-collab",
  deleteEvent: "/app/user-event/delete-event",
  userEventDetail: "/app/user-event/event-detail",
  eventGuestDetail: "/app/user-event//budget-service-guest",
  deleteService: "/app/user-event/budget-service/delete",
  deletePackage: "/app/user-event/budget-package/delete",
  addBudgetCategory: "/app/user-event/budget-category/add",
  categoryServices: "app/service/category-by-services",
  inviteTemplate: "/app/user-guest/invitation-template",

  // Guest Page ---->
  guest: "/app/user-guest",
  allDeleteGuest: "/app/user-guest/delete",
  updateGuest: "/app/user-guest/update-member/",
  importGuest: "/app/user-guest/import-guest/",

  // Side Nav ---->
  appRating: "/app/rating/customerapp",

  // Invite Friend ---->
  subscribe: "app/common/subscribe",

  //app Common

  testimonial: "app/common/testimonial",
  gallary: "app/common/gallary",
  storyLiked : "/app/story",


  // Common APIs
  common: {
    sendPushNotification : "common/send-push-notification",
    country: "common/country",
    city: "common/city/",
    eventType: "common/event-type",
    userEvent: "common/user-event",
    categories: "/common/categories",
    subCategories: "/common/sub-category",
    services: "/common/services",
    filteredAttribute: "/common/attribute",
    getUsers: "/common/get-users",
    updateSearchCount: "/common/provider-search-count",
    updateSrcSearchCount: "/common/service-search-count",
    imageUpload: "common/image-upload",
    fileUpload: "common/file-upload",
    servicesByEvent: "/common/services-by-event", // filter services by event id
    attributeByServices: "/common/attribute-by-services", // filter attribute by service id
    countVisitor: "/common/count-visitor",
    attributesByServiceCategory: "common/attribute",
  },
};

export default apiPath;
