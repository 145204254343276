import { Layout, Row, Col, Form, Input, Button, message } from "antd";
import { HeartFilled } from "@ant-design/icons";
import AndroidDownloadImg from "../../assets/images/android_download (1).png";
import IosDownloadImg from "../../assets/images/ios_download (1).png";
import CopyrightImg from "../../assets/images/copyright.png";
import TwitterImg from "../../assets/images/twitter.png";
import FacebookImg from "../../assets/images/facebook.png";
import GroupImg from "../../assets/images/Group.png";
import InstagramImg from "../../assets/images/instagram 2.png";
import YoutubeImg from "../../assets/images/youtube.png";
import LogoImg from "../../assets/images/logo img.png";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";


function Footer() {
  const { Footer: AntFooter } = Layout;
  const { request } = useRequest();
  const [form] = Form.useForm()
  const { userProfile, isLoggedIn } = useAuthContext()
  const navigate = useNavigate();

  const targetcontact = useRef(null);
  const targetChoose = useRef(null);

  const smoothScroll = (target) => {
    target.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollContact = () => {
    if (targetcontact.current) {
      smoothScroll(targetcontact.current);
    }
  };

  const handleScrollChoose = () => {
    if (targetChoose.current) {
      smoothScroll(targetChoose.current);
    }
  };

  const onSubscribe = (values) => {
    const payload = { ...values }

    request({
      url: `${apiPath.subscribe}`,
      method: "Post",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          form.resetFields()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
        console.log(data, "onSubscribe")
      },
      onError: (err) => {
        ShowToast(err?.response?.data?.message, Severty.ERROR)
        console.log(err);
      }
    })
  }
  const handleNavigate = ()=>{
    if(!isLoggedIn) return navigate("/login")
      navigate("/invite")
  }

  return (
    <section className="bg-img3">
      <div className="container for-width">
        <div className="for-border">
          <div className="row gap-3 gap-md-0">
            <div className="col-md-4 col-lg-3 col-12">
              <div className="for-fllx">
                <div className="logo-img4">
                  <img src={LogoImg} alt="#" />
                </div>
                <p>
                  {lang("Transforming your vision into unforgettable events.")}{" "}
                  {lang("Your trusted partner for seamless and memorable celebrations.")}
                </p>
                <h3>{lang("Download App")}</h3>
                <div className="logo-android main-play-logo">
                  <div>
                  <Link  to="https://play.google.com/store/apps/details?id=com.planit.customer" target="_blank">
                    {/* <a href=""> */}
                      <img src={AndroidDownloadImg} />
                    {/* </a> */}
                  </Link>
                    
                  </div>
                  <div >
                  <Link  to="https://apps.apple.com/us/app/planit/id6737801742" target="_blank">
                    {/* <a href=""> */}
                      <img src={IosDownloadImg} />
                    {/* </a> */}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3  col-12">          
              <ul className="list2 for-res">  
               <li> <h1>{lang("Pages")}</h1> </li>
                <li>
                  <Link  to={"/login"}  className="nav-link" >  {lang("Landing  Page")}  </Link>
                  <Link  to={"/privacyPolicies"}  className="nav-link"    >  {lang("Privacy Policy")} </Link>
                  <Link  to={"/aboutUs"}  className="nav-link"    >  {lang("About us")} </Link>
                  <Link  to={"/home-gallary"} onClick={() => navigate("/home-gallary")}  className="nav-link"  >{lang("Gallery")}  </Link>
                </li>
              </ul>
            </div>
            {!isLoggedIn ? 
             <div className="col-md-4 col-lg-3 col-12">
             <ul className="list2 subscribe-1">
               <li className=""> <h1>{lang("Subscribe")}</h1>   </li>
               <li> <p className="mt-2">  {lang("Unlock exclusive benefits and enjoy hassle-free transportation with our Planit Application Subscription")}  </p>  </li>
               <li>
                 <Form  form={form}  onFinish={onSubscribe}  >
                   <div className="gmail-box-1">
                     <Form.Item
                       name="email"
                     rules={[
                       {
                         type: 'email',
                         message: lang('The input is not valid E-mail!'),
                       },
                       {
                         required: true,
                         message: lang('Please input your E-mail!'),
                       },
                     ]}
                     >
                       <Input placeholder={lang("Enter Email")} />
                     </Form.Item>
                     <Button htmlType="submit">
                       {lang("Subscribe")}
                     </Button>
                   </div>
                 </Form>


               </li>
             </ul>
          
           </div>
           : 
           <>
             <div className="col-md-4 col-lg-3 col-12">
             
              <ul className="list2 subscribe-1">
                <li className=""> <h1>{lang("Invite Friends")}</h1>   </li>
                {/* <li> <p className="mt-2">  {lang("Invite 10 New friends to join our app and get 20 Loyalty Points to use in amazing rewards!")}  </p>  </li> */}
                <li>
                <div className="invt-text">
                  <button onClick={()=> handleNavigate()}>
                    <a >{lang("Invite Friends")}</a>
                  </button>
                </div>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3 col-12 d-none d-lg-block">
            <div className="invt-img">
                  <img src={GroupImg} alt="" />
                </div>
            </div>
           </>
            }
          
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="copy">
              <img src={CopyrightImg} alt="#" />
              {lang("Copyright 2024, All Rights Reserved by Beirut Event Management LLC")}
            </div>
          </div>
          <div className="col-md-4">
            <ul className="list3">
              {/* <img src={TwitterImg} onClick={() => window.open("https://x.com/?lang=en", "_blank")} /> */}
              <Link  to="https://www.facebook.com/people/Planit/61560559170603/" target="_blank">
              <img src={FacebookImg} />
              </Link>
              <Link to={"https://www.instagram.com/app.plan.it?igsh=MWQ4am02ZGdzcDQ2Zg=="} target="_blank">
              <img src={InstagramImg}  />
              </Link >
              {/* <img src={YoutubeImg} onClick={() => window.open("https://www.youtube.com/", "_blank")} /> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
