import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  Col,
  Form,
  Select,
  Radio,
  Input,
  Tabs,
  Button,
  Image,
  DatePicker,
  Upload,
  Row,
  InputNumber,
  message,
} from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";

import Serviceback1Img from "../../assets/images/service-img-back.png";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import Loader from "../../components/Loader";
import { UploadOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import {
  ColorCircleComponent,
  handleSubmitServiceAttr,
} from "../../helper/functions";
import { useAppContext } from "../../context/AppContext";

function RequestQuoteIndex() {
  const navigate = useNavigate();
  const params = useParams();
  const { request } = useRequest();
  const [form] = Form.useForm();
  const [proServices, setProServices] = useState([]);
  const [proPkg, setProPkg] = useState([]);
  const [selectedPkg, setSelectedPkg] = useState(null);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [srcAttributes, setsrcAttributes] = useState([]);
  const [attrLoading, setAttrLoading] = useState(false);
  const [showInfoCol, setShowInfoCol] = useState({});
  const [price, setPrice] = useState(0);
  const { language } = useAppContext();
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("useFor");
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
  const handleImage = (data) => {
    data.length > 0 ? setImage(data[0].url) : setImage("");
  };

  const getProviderSerivces = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.providerServices}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (!data.status) return;
        setProServices(data.data);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const getSrcttributes = (categoryId,serviceId) => {
    setAttrLoading(true);
    request({
      url: `${apiPath.common.attributesByServiceCategory}/${categoryId}/${serviceId}`,
      method: "GET",
      onSuccess: ({data}) => {
        setAttrLoading(false);
        setsrcAttributes(data?.data ?? []);
      },
      onError: (err) => {
        setAttrLoading(false);
        console.log(err);
      },
    });
  };

  const getProviderPackage = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.providerPackages}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (!data.status) return;
        setProPkg(data.data);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const onCreate = (values) => {
    const { package_id, service_id, description, document, ...attributes } =
      values;
    let serviceAttr =
      handleSubmitServiceAttr(
        attributes,
        showInfoCol,
        srcAttributes,
        type
      ) ?? [];

    const payload = {
      service_id: service_id ? service_id : null,
      package_id: package_id ? package_id : null,
      description: description,
      image: image,
      price: price,
      vendor_id: params.id ? params.id : null,
      category_id: proServices?.category_id ? proServices?.category_id : null,
      values: type !== "package" ? serviceAttr : [],
    };

    if (type === "package") {
      payload.pkg_services = selectedPkg?.package_service_id?.map(
        (service) => ({
          pkg_service_id: service?.service_id?._id,
          price: service?.discount_price ? service?.discount_price : service?.original_price || 0,
          options: service?.admin_attributes?.map((attribute) => {
            const attributeId = attribute?._id;
            const attributeName = attribute?.name;
            const attributeArName = attribute?.ar_name;
            const serviceId = service?._id;
            console.log(serviceId, "serviceId");
            // Find the matching key in attributes object
            const matchingKey = Object.keys(attributes).find(
              (key) => key.startsWith(serviceId) && key.includes(attributeName)
            );
            if (matchingKey) {
              const [serviceIdPart, ...nameParts] = matchingKey.split("_");
              const actualName = nameParts.join("_");

              let value = attributes[matchingKey];

              let ar_value;
              if (Array.isArray(value)) {
                ar_value = value.map((val) => {
                  const option = attribute?.options?.find(
                    (item) => item?.name === val
                  );
                  return option?.ar_name || val;
                });
              } else {
                ar_value =
                  attribute?.options?.find((item) => item?.name === value)
                    ?.ar_name || value;
              }

              return {
                _id: attributeId,
                name: attributeName,
                ar_name: attributeArName,
                value: value ?? null,
                ar_value: ar_value ?? null,
                type: attribute?.type,
              };
            } else {
              return {
                _id: attributeId,
                name: attributeName,
                ar_name: attributeArName,
                value: null,
                ar_value: null,
                type: attribute?.type,
              };
            }
          }),
        })
      );
    }

    //  return console.log(payload,"payload");
    request({
      url:
        type !== "package"
          ? `${apiPath.requestQuote}`
          : `${apiPath.requestQuote}/add-quote-package`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        console.log(data, "onCreate");
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setTimeout(() => navigate("/quotation"), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  const handleServiceChange = (serviceId) => {
    const selected = proServices.find((service) => service?._id === serviceId);
    setPrice(  selected?.discount_price ? selected?.discount_price  : selected?.original_price);
    getSrcttributes(selected?.selected_category_id ,selected?.service_id?._id)
    form.resetFields(); 
    form.setFieldsValue({ service_id : serviceId });
  };

  const handlePkgChange = (pkgId) => {
    const selected = proPkg?.find((pkg) => pkg?._id === pkgId);
    setSelectedPkg(selected);
    setPrice(
      selected?.discount_price
        ? selected?.discount_price
        : selected?.original_price
    );
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    if (type === "package") {
      getProviderPackage(params.id);
    } else if (type === "service") {
      getProviderSerivces(params.id);
    }
  }, [params.id]);


  const handleInfoCol = (attributeName, value) => {
    console.log(attributeName, "attributeName", value, "value");
    setShowInfoCol((prev) => ({
      ...prev,
      [attributeName]: {
        show: value,
        info: value ? prev[attributeName]?.info || "" : null,
      },
    }));
  };

  const showName = (value) => {
    return language !== "en" && language !== null
      ? value?.[`${language}_name`] ?? value?.name
      : value?.name;
  };

  return (
    <Main>
      <>
        <section className="request-img-bg">
          <div className="requ-teext-mm">
            <h2>{lang("Request a Quote")} </h2>
          </div>
          <div className="ser-img-back terw">
            <img src={Serviceback1Img} alt="" />
          </div>
        </section>
        <section className="request-write-page">
          <div className="container">
            <div className="request-q-form">
              <div className="rqtxt-1">
                <h2>{lang("Request a Custom Quote")}</h2>
                <p>
                  {lang(
                    "Let us know your requirements, and we’ll tailor a solution  just for you. Our team is dedicated to providing personalized  services to meet your needs. Fill out the form below, and   we’ll get back to you with a detailed quote."
                  )}
                </p>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <Form
                  id="create"
                  form={form}
                  onFinish={onCreate}
                  layout="vertical"
                >
                  {type === "package" ? (
                    <Col span={24} md={24}>
                      <Form.Item
                        className="filtter-select  main-new-color-attributes"
                        label={lang("Package")}
                        name="package_id"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select the Package!"),
                          },
                        ]}
                      >
                        <Select
                          className="form-select"
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          }
                          placeholder={lang("Select Package")}
                          showSearch
                          onChange={handlePkgChange}
                        >
                          {proPkg?.map((item) => (
                            <Select.Option
                              key={item?._id}
                              label={item?.name}
                              value={item?._id}
                            >
                              {showName(item)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={24} md={24}>
                      <Form.Item
                        className="filtter-select  main-new-color-attributes"
                        label={lang("Service")}
                        name="service_id"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select the Service!"),
                          },
                        ]}
                      >
                        <Select
                          className="form-select form-select-newmain99"
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          }
                          placeholder={lang("Select Service")}
                          showSearch
                          onChange={handleServiceChange}
                        >
                          {proServices?.map((item) => (
                            <Select.Option
                              key={item?._id}
                              label={item?.name}
                              value={item?._id}
                            >
                            <div className="quets-search">
                             <div className="quote-image-select"><Image preview={false} src={item?.cover_image ? item?.cover_image :  item?.service_id?.image  ? item?.service_id?.image : notfound }/></div> 
                            <div className="quest-text">
                            <h6>{item?.name ? showName(item): showName(item?.service_id)}{" "}</h6>
                            <p>{item?.discount_price ? `JOD ${item?.discount_price}` : `JOD ${item?.original_price}`}</p>
                            </div>
                            </div>
                              
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  {type === "package" ? (
                    selectedPkg?.package_service_id?.map((service) => (
                      <div key={service?._id}>
                        <div className="new-main-class-ofServicemain">
                          <h5>{lang("Service")} : </h5>
                          <h6>
                            {showName(service?.service_id)}{" "}
                            {service?.discount_price
                              ? `JOD ${service?.discount_price}`
                              : `JOD ${service?.original_price}`}
                          </h6>
                        </div>
                        <div>
                          <h5 className="new-attribute-tag">
                            {lang("Attributes")}
                          </h5>
                        </div>
                        <Row gutter={24}>
                          {service?.admin_attributes?.map((attribute) => (
                            <Col span={24} md={12} key={attribute?._id}>
                              {attribute?.type === "radio" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                  valuePropName="checked"
                                >
                                  {attribute?.options?.map((option) => (
                                    <Checkbox
                                      checked={
                                        form.getFieldValue(
                                          `${service?._id}_${attribute?.name}`
                                        ) === option?.name
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          form.setFieldValue(
                                            `${service?._id}_${attribute?.name}`,
                                            option?.name
                                          );
                                        } else {
                                          form.setFieldValue(
                                            `${service?._id}_${attribute?.name}`,
                                            null
                                          );
                                        }
                                      }}
                                    >
                                      {showName(option)}
                                    </Checkbox>
                                  ))}
                                  {/* <Radio.Group>
                                            {attribute?.options?.map((option) => (
                                              <Radio key={option?.name} value={option?.name}>
                                                {showName(option)}
                                              </Radio>
                                            ))}
                                          </Radio.Group> */}
                                </Form.Item>
                              ) : attribute?.type === "boolean" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    checked={
                                      form.getFieldValue(
                                        `${service?._id}_${attribute?.name}`
                                      ) === true
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        form.setFieldValue(
                                          `${service?._id}_${attribute?.name}`,
                                          true
                                        );
                                      } else {
                                        form.setFieldValue(
                                          `${service?._id}_${attribute?.name}`,
                                          null
                                        );
                                      }
                                    }}
                                  >
                                    {lang("Yes")}
                                  </Checkbox>
                                  <Checkbox
                                    checked={
                                      form.getFieldValue(
                                        `${service?._id}_${attribute?.name}`
                                      ) === false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        form.setFieldValue(
                                          `${service?._id}_${attribute?.name}`,
                                          false
                                        );
                                      } else {
                                        form.setFieldValue(
                                          `${service?._id}_${attribute?.name}`,
                                          null
                                        );
                                      }
                                    }}
                                  >
                                    {lang("No")}
                                  </Checkbox>
                                </Form.Item>
                              ) : attribute?.type === "checkbox" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                >
                                  <Checkbox.Group>
                                    {attribute?.options.map((option) => (
                                      <Checkbox
                                        value={option?.name}
                                        key={option?.name}
                                        label={option?.name}
                                      >
                                        {showName(option)}
                                      </Checkbox>
                                    ))}
                                  </Checkbox.Group>
                                </Form.Item>
                              ) : attribute?.type === "color" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                  valuePropName="checked"
                                >
                                  {attribute?.options?.map((option) => (
                                    <Checkbox
                                      checked={
                                        form.getFieldValue(
                                          `${service?._id}_${attribute?.name}`
                                        ) === option?.name
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          form.setFieldValue(
                                            `${service?._id}_${attribute?.name}`,
                                            option?.name
                                          );
                                        } else {
                                          form.setFieldValue(
                                            `${service?._id}_${attribute?.name}`,
                                            null
                                          );
                                        }
                                      }}
                                    >
                                      {showName(option)}
                                      <ColorCircleComponent
                                        color={option?.name ? option?.name : ""}
                                      />
                                    </Checkbox>
                                  ))}
                                  {/* <Radio.Group>
                                            {attribute?.options?.map((option) => (
                                              <Radio
                                                value={option?.name}
                                                key={option?.name}
                                              >
                                               
                                                {showName(option)}
                                                <ColorCircleComponent
                                                  color={option?.name ? option?.name : ""}
                                                />
                                              </Radio>
                                            ))}
                                          </Radio.Group> */}
                                </Form.Item>
                              ) : attribute?.type === "dropdown" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                >
                                  <Select
                                    className="form-select"
                                    placeholder={`Select ${showName(
                                      attribute
                                    )}`}
                                  >
                                    {attribute?.options?.map((option) => (
                                      <Select.Option
                                        value={option?.name}
                                        key={option?.name}
                                      >
                                        {showName(option)}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              ) : attribute?.type === "file" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                >
                                  <SingleImageUpload
                                    fileType={FileType}
                                    imageType={"image"}
                                    btnName={"Image"}
                                    isDimension={false}
                                  />
                                </Form.Item>
                              ) : attribute?.type === "button" ? (
                                <Form.Item
                                  className="input-mb-o"
                                  name={`${service?._id}_${attribute?.name}`}
                                  // label={attribute?.name}
                                >
                                  <Checkbox
                                    valuePropName="checked"
                                    checked={
                                      !!showInfoCol[attribute?.name]?.show
                                    }
                                    onChange={(e) =>
                                      handleInfoCol(
                                        attribute?.name,
                                        e.target.checked
                                      )
                                    }
                                  >
                                    {showName(attribute)}
                                  </Checkbox>

                                  {showInfoCol[attribute?.name]?.show && (
                                    <Col span={12} md={24}>
                                      <Form.Item className="input-mb-o">
                                        <Input
                                          placeholder="Enter Info"
                                          value={
                                            showInfoCol[attribute?.name]
                                              ?.info || ""
                                          }
                                          onChange={(e) =>
                                            setShowInfoCol((prev) => ({
                                              ...prev,
                                              [attribute?.name]: {
                                                ...prev[attribute?.name],
                                                info: e.target.value,
                                              },
                                            }))
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                </Form.Item>
                              ) : attribute?.type === "inputNumber" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    className="number-inputs"
                                    placeholder={`Enter ${showName(
                                      attribute
                                    )}`}
                                  />
                                </Form.Item>
                              ) : attribute?.type === "textbox" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={`Enter ${showName(
                                      attribute
                                    )}`}
                                  />
                                </Form.Item>
                              ) : attribute?.type === "calendar" ? (
                                <Form.Item
                                  name={`${service?._id}_${attribute?.name}`}
                                  label={showName(attribute)}
                                  rules={[
                                    {
                                      required: attribute?.is_required,
                                      message: lang(
                                        `Please select ${showName(
                                          attribute
                                        )}!`
                                      ),
                                    },
                                  ]}
                                >
                                  <DatePicker format={"DD-MM-yyyy"} />
                                </Form.Item>
                              ) : null}
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))
                  ) : (
                    <Row gutter={24}>
                      <>
                        {srcAttributes?.length ? (
                          <Col span={24} md={24}>
                            <div>
                              <h5 className="new-attribute-tag">
                                {lang("Attributes")}
                              </h5>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {attrLoading ? <Loader/> : srcAttributes?.map((attribute) => (
                          <Col span={24} md={12} key={attribute?._id}>
                            {attribute?.type === "radio" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute
                                      )}!`
                                    ),
                                  },
                                ]}
                                valuePropName="checked"
                              >
                                {attribute?.options?.map((option) => (
                                  <Checkbox
                                    checked={
                                      form.getFieldValue(
                                        attribute?.name
                                      ) === option?.name
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        form.setFieldValue(
                                          attribute?.name,
                                          option?.name
                                        );
                                      } else {
                                        form.setFieldValue(
                                          attribute?.name,
                                          null
                                        );
                                      }
                                    }}
                                  >
                                    {showName(option)}
                                  </Checkbox>
                                ))}
                                {/* <Radio.Group>
                                  {attribute?._id?.options?.map((option) => (
                                    <Radio
                                      key={option?.name}
                                      value={option?.name}
                                    >
                                      {showName(option)}
                                    </Radio>
                                  ))}
                                </Radio.Group> */}
                              </Form.Item>
                            ) : attribute?.type === "boolean" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute
                                      )}!`
                                    ),
                                  },
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  checked={
                                    form.getFieldValue(attribute?.name) ===
                                    true
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      form.setFieldValue(
                                        attribute?.name,
                                        true
                                      );
                                    } else {
                                      form.setFieldValue(
                                        attribute?.name,
                                        null
                                      );
                                    }
                                  }}
                                >
                                  {lang("Yes")}
                                </Checkbox>
                                <Checkbox
                                  checked={
                                    form.getFieldValue(attribute?.name) ===
                                    false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      form.setFieldValue(
                                        attribute?.name,
                                        false
                                      );
                                    } else {
                                      form.setFieldValue(
                                        attribute?.name,
                                        null
                                      );
                                    }
                                  }}
                                >
                                  {lang("No")}
                                </Checkbox>
                              </Form.Item>
                            ) : attribute?.type === "checkbox" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute?._id
                                      )}!`
                                    ),
                                  },
                                ]}
                              >
                                <Checkbox.Group>
                                  {attribute?.options.map((option) => (
                                    <Checkbox
                                      value={option?.name}
                                      key={option?.name}
                                      label={option?.name}
                                    >
                                      {showName(option)}
                                    </Checkbox>
                                  ))}
                                </Checkbox.Group>
                              </Form.Item>
                            ) : attribute?.type === "color" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute
                                      )}!`
                                    ),
                                  },
                                ]}
                              >
                                {attribute?.options?.map((option) => (
                                  <Checkbox
                                    checked={
                                      form.getFieldValue(
                                        attribute?.name
                                      ) === option?.name
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        form.setFieldValue(
                                          attribute?.name,
                                          option?.name
                                        );
                                      } else {
                                        form.setFieldValue(
                                          attribute?.name,
                                          null
                                        );
                                      }
                                    }}
                                  >
                                    {showName(option)}
                                    <ColorCircleComponent
                                      color={option?.name ? option?.name : ""}
                                    />
                                  </Checkbox>
                                ))}
                                {/* <Radio.Group>
                                  {attribute?.options?.map((option) => (
                                    <Radio
                                      value={option?.name}
                                      key={option?.name}
                                    >
                                      {showName(option)}
                                      <ColorCircleComponent
                                        color={option?.name ? option?.name : ""}
                                      />
                                    </Radio>
                                  ))}
                                </Radio.Group> */}
                              </Form.Item>
                            ) : attribute?.type === "dropdown" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute
                                      )}!`
                                    ),
                                  },
                                ]}
                              >
                                <Select
                                  className="form-select"
                                  placeholder={`Select ${showName(
                                    attribute
                                  )}`}
                                >
                                  {attribute?.options?.map((option) => (
                                    <Select.Option
                                      value={option?.name}
                                      key={option?.name}
                                    >
                                      {showName(option)}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            ) : attribute?.type === "file" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute
                                      )}!`
                                    ),
                                  },
                                ]}
                              >
                                <SingleImageUpload
                                  fileType={FileType}
                                  imageType={"image"}
                                  btnName={"Image"}
                                  isDimension={false}
                                />
                              </Form.Item>
                            ) : attribute?.type === "button" ? (
                              <Form.Item
                                className="input-mb-o"
                                name={attribute?.name}
                              >
                                <Checkbox
                                  valuePropName="checked"
                                  checked={
                                    !!showInfoCol[attribute?.name]?.show
                                  }
                                  onChange={(e) =>
                                    handleInfoCol(
                                      attribute?.name,
                                      e.target.checked
                                    )
                                  }
                                  value={
                                    showInfoCol[attribute?.name]?.show
                                  }
                                >
                                  {showName(attribute)}
                                </Checkbox>

                                {showInfoCol[attribute?.name]?.show && (
                                  <Col span={12} md={24}>
                                    <Form.Item className="input-mb-o">
                                      <Input
                                        placeholder="Enter Info"
                                        value={
                                          showInfoCol[attribute?.name]
                                            ?.info || ""
                                        }
                                        onChange={(e) =>
                                          setShowInfoCol((prev) => ({
                                            ...prev,
                                            [attribute?.name]: {
                                              ...prev[attribute?.name],
                                              info: e.target.value,
                                            },
                                          }))
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                              </Form.Item>
                            ) : attribute?.type === "inputNumber" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute
                                      )}!`
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  className="number-inputs"
                                  placeholder={`Enter ${showName(
                                    attribute
                                  )}`}
                                />
                              </Form.Item>
                            ) : attribute?.type === "textbox" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute
                                      )}!`
                                    ),
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`Enter ${showName(
                                    attribute
                                  )}`}
                                />
                              </Form.Item>
                            ) : attribute?.type === "calendar" ? (
                              <Form.Item
                                name={attribute?.name}
                                label={showName(attribute)}
                                rules={[
                                  {
                                    required: attribute?.is_required,
                                    message: lang(
                                      `Please select ${showName(
                                        attribute
                                      )}!`
                                    ),
                                  },
                                ]}
                              >
                                <DatePicker format={"DD-MM-yyyy"} />
                              </Form.Item>
                            ) : attribute?.type === "package" ? (
                              <div>
                                {attribute?.options?.map((option) => (
                                  <Form.Item
                                    key={option?._id}
                                    label={`${showName(
                                      attribute
                                    )} (${showName(option)})`}
                                    name={option?.name}
                                  >
                                    <Select
                                      mode="tags"
                                      style={{ width: "100%" }}
                                      placeholder={`Select ${showName(option)}`}
                                    >
                                      {option?.tags?.map((tag) => (
                                        <Select.Option key={tag} value={tag}>
                                          {tag}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                ))}
                              </div>
                            ) : null}
                          </Col>
                        ))}
                      </>
                    </Row>
                  )}

                  <Col span={24} md={24}>
                    <Form.Item
                      className=""
                      label={lang("Comment")}
                      name="description"
                    >
                      <Input.TextArea
                        placeholder={lang("Enter your comments")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <div className="col-md-6">
                      <Form.Item
                        className="upload_wrap upload_wrapmain-newww"
                        name="document"
                        label={lang("Upload Image")}
                      >
                        <SingleImageUpload
                          value={image}
                          fileType={FileType}
                          imageType={"image"}
                          btnName={lang("Image")}
                          onChange={(data) => handleImage(data)}
                          isDimension={false}
                        />
                      </Form.Item>

                      <div className="mt-2 add-img-product">
                        {" "}
                        <Image width={120} src={image ?? notfound}></Image>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} className="mt-3 Send-Requestmain-biutton">
                    <Button
                      form="create"
                      type="primary"
                      className="submit-button"
                      htmlType="submit"
                    >
                      {lang("Send Request")}
                    </Button>
                    {/* <Button form="create" type="primary" htmlType="submit">Send Request</Button> */}
                  </Col>
                </Form>
              )}
            </div>
          </div>
        </section>
      </>
    </Main>
  );
}

const ColorPickerComponent = ({ value = "#1677ff", onChange }) => {
  const [color, setColor] = useState(value);

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    onChange(newColor.hex);
  };

  return <SketchPicker color={color} onChange={handleChange} />;
};

export default RequestQuoteIndex;
