import { Col, Row, Form, Modal, Input, Button } from "antd";
import Stories from "react-insta-stories";
import { formatStringDate, formatTimeAgo } from "../helper/functions";
import { useAuthContext } from "../context/AuthContext";
import lang from "../helper/langHelper";
import { useState } from "react";
import {
  HeartOutlined,
  SendOutlined,
  ArrowRightOutlined,
  LikeOutlined,
  HeartFilled,
} from "@ant-design/icons";
import ChatRepository from "../pages/Message/ChatRepository";
import { serverTimestamp } from "firebase/firestore";
import { Severty, ShowToast } from "../helper/toast";
import Prouser from "../assets/images/user.png";
import apiPath from "../constants/apiPath";
import useRequest from "../hooks/useRequest";
import Login from "../pages/Auth/Login";

const ReadStory = ({ show, hide, refresh, data, providerData }) => {
  const [loading, setLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(data?.is_liked);
  const [msg, setMsg] = useState("");
  const chatRepo = ChatRepository();
  const supportedVideoExtensions = [".mp4", ".ogg", ".webm", ".mpeg"];
  const { request } = useRequest();
  const { userProfile } = useAuthContext();
  const { isLoggedIn } = useAuthContext();
  const [showLogin, setShowLogin] = useState(false);

  let isVideo = supportedVideoExtensions.some((ext) =>
    data?.image?.toLowerCase()?.endsWith(ext)
  );

  const stories = [
    {
      url: data?.image,
      duration: 5000,
      type: isVideo ? "video" : "image",
      header: {
        heading: data?.title ? data?.title : providerData?.name,
        subheading: `Posted ${formatTimeAgo(data?.created_at)}`,
        profileImage: providerData?.logo
          ? providerData?.logo
          : providerData?.selected_category_id?.image
          ? providerData?.selected_category_id?.image
          : Prouser,
      },
    },
  ];

  const sendMessage = () => {
    if (!isLoggedIn) return setShowLogin(true);

    if (msg && msg?.trim()) {
      let userId = JSON.parse(localStorage.getItem("userProfile"))?._id;

      const groupdId = userId + "_" + providerData?.user_id;

      let oppositeUserData = {
        image: providerData?.logo,
        mobileNumber: providerData?.mobile_number,
        name: providerData?.name,
        unseenCount: 0,
      };
      let quotationData = {
        title: "",
        image: "",
        budget: "",
        description: "",
        isQuotation: false,
      };

      chatRepo.createGroup(
        groupdId,
        userId,
        providerData?.user_id,
        oppositeUserData,
        quotationData
      );

      const message = {
        groupId: groupdId,
        message: msg,
        sendTime: serverTimestamp(),
        senderId: userId,
        fromStory: true,
        storyUrl: data?.image,
        type: "text",
      };

      console.log(message, " : : message");
      chatRepo.updateSeenMessages(groupdId, userId, msg);
      chatRepo.sendMessage(message);
      ShowToast("Message Sent!", Severty.SUCCESS);
      setMsg("");
      //send Notification

      const payload = {
        user_id: providerData?.user_id ? providerData?.user_id : "",
        title: "New Story Reply",
        description: `You have received a reply to your story from ${
          userProfile?.name || "a user"
        }. Check it out now!`,
        ar_title: "رد جديد على القصة", // Arabic translation of "New Story Reply"
        ar_description: `لقد تلقيت ردًا على قصتك من ${
          userProfile?.name || "مستخدم"
        }. تحقق منه الآن!`, // Arabic translation with dynamic user name
        create_notification: true,
      };

      request({
        url: apiPath.common.sendPushNotification,
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          if (data.status) {
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (err) => {
          console.log(err);
          ShowToast(err, Severty.ERROR);
        },
      });
    }
  };

  const onLiked = (values) => {
    if (!isLoggedIn) return setShowLogin(true);
    setLoading(true);
    request({
      url: `${apiPath.storyLiked}/${data?._id}`,
      method: "POST",
      data: {},
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          refresh();
          setIsLiked((prev) => !prev);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err?.response?.data?.errors?.[0]?.message, Severty.ERROR);
        console.log(err);
      },
    });
  };

  return (
    <>
      <Modal
        open={show}
        cancelText={<ArrowRightOutlined className="main-back--button-csss" />}
        width={360}
        onCancel={hide}
        centered
        okButtonProps={{
          form: "story",
          htmlType: "submit",
          loading: loading,
        }}
        className="full-width-modal tab_modal deleteWarningModal eleteWarningModal-hhht5657"
      >
        <Form className="" id="story" layout="vertical">
          <Row gutter={[16, 0]} className="stories-container">
            <Col span={24} sm={24} className="mx-auto p-0">
              <Stories
                stories={stories.map((story) => ({
                  ...story,
                  content: () =>
                    story.type === "video" ? (
                      <video
                        src={story.url}
                        controls
                        autoPlay
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <>
                        <img
                          src={story.url}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "100%",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            border: "2px solid white",
                          }}
                        >
                          <img
                            src={story.header.profileImage}
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </>
                    ),
                }))}
                defaultInterval={3000}
                width={"100%"}
                className={("stories", "new-class")}
              />
              <div className="message-container">
                <Input
                  placeholder="Send Message"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  className="message-input"
                />
                {isLiked ? (
                  <Button
                    onClick={() => {
                      onLiked();
                    }}
                    icon={
                      <HeartFilled
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                      />
                    }
                    className="icon-button"
                  />
                ) : (
                  <Button
                    onClick={() => {
                      onLiked();
                    }}
                    icon={
                      <HeartOutlined
                        style={{
                          color: "black",
                          cursor: "pointer",
                        }}
                      />
                    }
                    className="icon-button"
                  />
                )}

                <Button
                  onClick={() => {
                    sendMessage();
                  }}
                  icon={<SendOutlined />}
                  className="icon-button"
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      {showLogin && (
        <Login
          show={showLogin}
          hide={() => {
            setShowLogin(false);
          }}
          url={true}
        />
      )}
    </>
  );
};

export default ReadStory;
