import React, { useState, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import SearchImg from "../../assets/images/ion_search-outline.png";
import ProfilepicImg from "../../assets/images/chat-img.png";
import Send1Img from "../../assets/images/send-buaa.png";
import Send133Img from "../../assets/images/john abraham.png";
import {
  query,
  addDoc,
  collection,
  serverTimestamp,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { useAuthContext } from "../../context/AuthContext";
import moment from "moment";
import ChatRepository from "./ChatRepository";
import { Image, Upload, Button, Avatar } from "antd";
import UploadBtnImg from "../../assets/images/paper-pin.png";
import useRequest from "../../hooks/useRequest";
import { Col } from "antd";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import prouser from "../../assets/images/user.png";

function Chat() {
  const [selected, setSelected] = useState();
  const [user, setUser] = useState();
  const [groups, setGroups] = useState([]);
  const chatRepo = ChatRepository();

  useEffect(() => {
    const unsubscribe = chatRepo
      .getAllMyGroups()
      .orderBy("last_message.time", "desc")
      .onSnapshot((snapshot) => {
        const groupData = snapshot.docs.map((doc) => doc.data());
        console.log(groupData, " \n:: Doc :: ");
        setGroups(groupData);

        if (groupData?.length && !selected) {
          const userData = groupData[0]?.userData[0];
          const userKeys = Object.keys(userData);
          const oppositeUser =
            userData[
              userKeys.find(
                (key) =>
                  key !== JSON.parse(localStorage.getItem("userProfile"))?._id
              )
            ];
          setUser(oppositeUser);
          setSelected(groupData[0]?.groups[0] + "_" + groupData[0]?.groups[1]);
        }
      });

    return () => unsubscribe();
  }, []);

  return (
    <Main>
      <section>
        <div className="container">
          <div className="row">
            <Inbox
              groups={groups}
              setSelected={setSelected}
              setUser={setUser}
            />

            <ChatDetails selected={selected} user={user} />
          </div>
        </div>
      </section>
    </Main>
  );
}

const Inbox = ({ groups, setSelected, setUser }) => {
  const [gd, setGd] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const handleSearch = (val) => {
    const temp = groups?.filter((group, index) => {
      const userData = group?.userData?.[0];
      const userKeys = Object.keys(userData);
      const oppositeUser =
        userData[
          userKeys.find(
            (key) =>
              key !== JSON.parse(localStorage.getItem("userProfile"))?._id
          )
        ];
      if (
        oppositeUser?.name?.toLowerCase()?.includes(val?.toLowerCase()?.trim())
      ) {
        return group;
      }
    });
    console.log(temp, " \n:: gd");
    setGd(temp);
  };

  useEffect(() => {
    setGd(groups);
  }, [groups]);

  useEffect(() => {
    if (groups.length > 0) {
      setSelectedGroup(groups?.[0]?.groups?.[0] + "_" + groups?.[0]?.groups?.[1]);
   
    }
  }, [groups]);

  return (
    <Col span={24} sm={24} lg={6}>
      <div className="main-chat-div-1">
        <form className="search-chat d-flex mb-3">
          <button type="submit" className="btn btn-light">
            <img src={SearchImg} alt="" />
          </button>
          <input
            type="text"
            placeholder={lang("Search") + "..."}
            onChange={(e) => handleSearch(e.target.value)}
            name="search"
            className="form-control"
          />
        </form>
        <div className="users-chat-massage-maain">
          {gd?.length ? (
            gd.map((group, index) => {
              const isSelected =  selectedGroup === group?.groups[0] + "_" + group?.groups[1];
              const userData = group.userData[0];
              const userKeys = Object.keys(userData);
              const oppositeUser =
                userData[
                  userKeys.find(
                    (key) =>
                      key !==
                      JSON.parse(localStorage.getItem("userProfile"))?._id
                  )
                ];
              return (
                <div
                  key={index}
                  onClick={() => {
                    setUser(oppositeUser);
                    setSelected(group?.groups[0] + "_" + group?.groups[1]);
                    setSelectedGroup(group?.groups[0] + "_" + group?.groups[1]); 
                  }}
                  className={`users-chat-massage d-flex align-items-center ${
                    isSelected ? "active-chat-user" : ""
                  }`} 
                >
                  <div className="img-chat mr-3">
                    <img
                       src={
                        
                        
                        (oppositeUser.image).includes("https://api-ap-south-mum-1.openstack") ? oppositeUser.image : prouser}
                      alt=""
                      className="rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                  <div className="chat-txxt">
                    <div className="chat_1-maa d-flex justify-content-between">
                      <h2 className="h5 mb-1">{oppositeUser.name}</h2>
                      <p className="text-muted small">
                        {moment(group?.last_message?.time?.toDate())?.format(
                          "hh:mm A"
                        )}
                      </p>
                    </div>
                    <div className="massage-chat-main">
                      <h3 className="small text-muted">
                        {group.last_message.message}
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="">
              <div className="main-chat-div-1">
                <div className="users-chat-massage-maain text-center">
                  <img src={ProfilepicImg} alt="" className="rounded-circle" />
                  <p>{lang("Oops! no Message")}</p>
                  <p>{lang("No message in your inbox yet!")}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

const ChatDetails = ({ selected, user }) => {
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("text");
  const [messages, setMessages] = useState([]);
  const chatRepo = ChatRepository();
  const { request } = useRequest();
  const userId = JSON.parse(localStorage.getItem("userProfile"))?._id; // Assuming userProfile is stored in localStorage
  const scroll = useRef();

  useEffect(() => {
    if (!selected) return;

    const unsubscribe = chatRepo
      .getAllMessages(selected)
      .onSnapshot((snapShot) => {
        const data = snapShot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(data);
        scroll?.current?.scrollIntoView({ behavior: "smooth" });
        console.log(data);
      });

    return () => unsubscribe();
  }, [selected]);

  const handleSendMessage = (e) => {
    e.preventDefault();

    console.log("selected: ", selected);

    if (msg.trim()) {
      const message = {
        groupId: selected,
        message: msg,
        sendTime: serverTimestamp(),
        senderId: userId,
        type: type,
      };

      console.log(message, " : : message");
      chatRepo.updateSeenMessages(selected, userId, msg);
      chatRepo.sendMessage(message);
      setMsg("");
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    const data = new FormData();
    data.append("image", file);
    request({
      url: apiPath.common.imageUpload,
      header: {
        contentType: "multipart/form-data",
      },
      method: "POST",
      data: data,
      onSuccess: (data) => {
        setMsg(data?.data);
        setType("image");
        console.log(data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  // Automatically scroll to the last message when messages update
  useEffect(() => {
    if (scroll.current) {
      scroll.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]); // This will trigger every time messages array changes

  return (
    <Col span={24} sm={24} lg={18}>
      <div className="chat-box-main_2">
        <div className="main-chat-wtp">
          <div className="chat-day">{/* <h2>Today</h2> */}</div>
          {messages.map((message) => {
            if (message?.senderId == userId) {
              return <Send data={message} key={message?.id} user={user} />;
            }

            return <Receive user={user} data={message} key={message?.id} />;
            // return <Message key={message?.id} message={message} userId={JSON.parse(localStorage.getItem("userProfile"))?._id} />
          })}
          <span ref={scroll}></span>
        </div>
        <div className="send-msg-button">
          <form onSubmit={handleSendMessage} className="send_massage">
            <div className="message-sending-56ggamain-5">
              <input
                type="file"
                onChange={handleChange}
                id="file-input"
                style={{ display: "none" }}
              />
              <label
                htmlFor="file-input"
                className="file-input-label file-input-labelmain-45"
              >
                <img
                  src={UploadBtnImg}
                  alt="Upload"
                  style={{ cursor: "pointer" }}
                />
              </label>
              {type === "image" && msg ? (
                <Image width={40} src={msg} alt="" />
              ) : (
                ""
              )}
            </div>
            <input
              type="text"
              value={msg}
              onChange={({ target }) => {
                setMsg(target.value);
                setType("text");
              }}
              placeholder={lang("Type") + "..."}
              name="search"
              // style={styles.input}
              style={{
                display: type === "image" && msg ? "none" : "block",
                ...styles.input,
              }}
            />
            <button type="submit" className="message-sending-56gga">
              <img src={Send1Img} alt="Send" />
            </button>
          </form>
        </div>
      </div>
    </Col>
  );
};

const Send = ({ data ,user}) => {
  const supportedVideoExtensions = [".mp4", ".ogg", ".webm", ".mpeg"];

  const isVideo = (url) => {
    return supportedVideoExtensions.some((ext) =>
      url?.toLowerCase()?.endsWith(ext)
    );
  };

  return (
    <div class="msg-1">
      {data.type == "text" ? (
        <div className="mian-new-reply-story-new-main">
       
            {data?.fromStory ? (
              <>
                 <div className="message-card">
                <div className="main-new-chat-flex-option">
                  <div className="header123reply-ikjuh67">
                    <div className="header123reply">{user?.name ?? ""}</div>
                    <div className="content">
                      {/* <span className="emojis">🥺🥰❤️</span> */}
                    </div>
                  </div>
                  <div className="thumbnail">
                    {" "}
                    {isVideo(data?.storyUrl) ? (
                      <video controls src={data?.storyUrl} />
                    ) : (
                      <Image src={data?.storyUrl} />
                    )}
                  </div>
                </div>
                <p class="chat-reply-text-by-story">
                  {data?.message?.includes("http") ||
                  data?.message?.includes(".") ? (
                    <a href={data?.message} target="_blank">
                      {data?.message}
                    </a>
                  ) : (
                    data?.message
                  )}
                </p>{" "}
                </div>
              </>
            ) : (
              ""
            )}
       
          {data?.fromStory !== true ? (
            <h3 class="">
              {data?.message?.includes("http") ||
              data?.message?.includes(".") ? (
                <a href={data?.message} target="_blank">
                  {data?.message}
                </a>
              ) : (
                data?.message
              )}
            </h3>
          ) : (
            ""
          )}{" "}
        </div>
      ) : data.type == "pdf" ? (
        <a
          href={data.message}
          target="_blank"
          className="rounded-3 overflow-hidden"
        >
          <img style={{ height: 150 }} src="/assets/img/pdf_icon.png" alt="" />
        </a>
      ) : data.type == "video" ? (
        <video width="320" height="240" controls>
          {" "}
          <source src={data.message} type="video/mp4" />{" "}
        </video>
      ) : (
        <Image
          src={data.message}
          alt=""
          style={{ width: "120px", height: "120px", borderRadius: "5px" }}
        />
      )}
      <p>{moment(data?.sendTime?.toDate())?.format("lll")}</p>
      <div class="msgTime mt-2 pt-1 float-end ms-3">
        {data?.is_read ? (
          <img
            className="ms-1"
            style={{ width: 15, height: 15 }}
            src="/assets/img/seen.png"
            alt=""
          />
        ) : (
          <img
            className="ms-1"
            style={{ width: 15, height: 15 }}
            src="/assets/img/unseen.png"
            alt=""
          />
        )}
      </div>
    </div>
  );
};

const Receive = ({ data, user }) => {
  console.log(user);
  return (
    <div class="  msg-2  ">
      <div class="profile-img">
        <img class=" "  src={user.image.includes("https://api-ap-south-mum-1.openstack") ? user.image : prouser} alt="" />
      </div>
      <div class="prof-chat">
        <h2>{user?.name ?? "Jhon"}</h2>
        {data.type == "text" ? (
          <h4 class="">
            {data?.message?.includes("http") || data?.message?.includes(".") ? (
              <a href={data?.message} target="_blank">
                {data?.message}
              </a>
            ) : (
              data?.message
            )}
          </h4>
        ) : data.type == "pdf" ? (
          <a
            href={data.message}
            target="_blank"
            className="rounded-3 overflow-hidden"
          >
            <img
              style={{ height: 150 }}
              src="/assets/img/pdf_icon.png"
              alt=""
            />
          </a>
        ) : data.type == "video" ? (
          <video width="320" height="240" controls>
            {" "}
            <source src={data.message} type="video/mp4" />{" "}
          </video>
        ) : (
          <Image
            src={data.message}
            alt=""
            style={{ width: "120px", height: "120px", borderRadius: "5px" }}
          />
        )}

        <p class=" ">{moment(data?.sendTime?.toDate())?.format("lll")}</p>
      </div>
    </div>
  );
};

const styles = {
  sendMassageForm: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderTop: "1px solid #ddd",
    backgroundColor: "#f5f5f5",
  },
  input: {
    flex: 1,
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "20px",
    marginRight: "10px",
  },
  button: {
    border: "none",
    background: "none",
    cursor: "pointer",
  },
};

export default Chat;
